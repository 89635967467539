import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'ui-progress-spinner',
  standalone: true,
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinner],
})
export class ProgressSpinnerComponent {
  @Input() public strokeWidth = 10;
  @Input() public diameter = 100;
  @Input()
  @HostBinding('style.background-color')
  public backgroundColor: string | undefined;

  @Input()
  @HostBinding('class')
  public positioning: 'fixed' | 'absolute' = 'fixed';
}
